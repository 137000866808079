import React from 'react';
import velocityLogo from '../../images/velocity-logo.png';

class Header extends React.Component {

	render() {
		const isLoggedIn = this.props.isLoggedIn;
		const logout = this.props.logout;

		return (
			<header>
	            <img src={velocityLogo} alt="logo" className="header-logo" />

	            {(isLoggedIn) && (
	            	<React.Fragment>
		            	<div className="account-controls">		            		
			                <button className="green" onClick={logout}>Save & Exit</button>
			            </div>
			        </React.Fragment>
	            )}
	        </header>
		)
	}
}

export default Header;