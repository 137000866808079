import React from 'react';

import { numberToPrice } from '../../helpers/uiFunctions';
// import { Flex, Box, Text } from "rebass";
import * as Api from './../../api/endpoints';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { FormComponent, FormContainer } from 'react-authorize-net';
import { authorizeCaptureTransaction } from '../../api/fetchHelpers';
let serviceFee = 0;
let adFee = 0;

class StepFour extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingClient: false,
		};
	}

	onErrorHandler = (response) => {
		console.log("onErrorHandler")
	};

	onSuccessHandler = (response) => {
		this.setState({ loadingClient: true });

		// console.log("onSuccessHandler" + JSON.stringify(response))
		const transactionObject = {
			"createTransactionRequest": {
				"merchantAuthentication": {
					"name": this.props.authCreds.apiLoginId,
					"transactionKey": this.props.authCreds.transactionKey,
				},
				"transactionRequest": {
					"transactionType": "authCaptureTransaction",
					"amount": serviceFee + adFee,
					"payment": {
						"opaqueData": {
							"dataDescriptor": response.opaqueData.dataDescriptor,
							"dataValue": response.opaqueData.dataValue
						}
					}
				}
			}
		}

		// console.log(" transactionObject is " + JSON.stringify(transactionObject))
		fetch(Api.authCaptureTransaction, authorizeCaptureTransaction(transactionObject))
			.then(response => {
				if (response.ok) { return response.json(); }
				else { throw new Error('Something went wrong getting the client role from sso ...'); }
			})
			.then(data => {
				// console.log(data);
				this.setState({ loadingClient: false });
				
				toast("Transaction done successful", {
					position: "bottom-center",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					type: 'success'
				});

				// save the transaction to clients service
				var addTransaction = this.props.addTransaction;
				var transactionId = data.transactionResponse.transId;
				var name = document.getElementById("cardName").value;
				var amount = serviceFee + adFee;
				addTransaction(transactionId, name, amount)
				// end ------
				// set the client to active in CDE since their onboarding is complete
				var update = this.props.updateClient;
				update("status", "active");
				// end ------
				// send email to HR with client info for adding to qb
				var sendAlert = this.props.sendAlert;
				sendAlert();
				// end ------
			})
			.catch(error => console.log(error));
	};


	render() {
		const currentClient = this.props.currentClient;
		const authCreds = this.props.authCreds;
		const transactionComplete = this.props.transactionComplete;
		if (currentClient) {
			if (currentClient.servicePackage === "lite") { serviceFee = 399; }
			else if (currentClient.servicePackage === "prime") { serviceFee = 1700; }
			else if (currentClient.servicePackage === "premium") { serviceFee = 2900; }
			else if (currentClient.servicePackage === "platinum") { serviceFee = 3500; }
			if (currentClient.adPackage === "250") { adFee = 100; }
			else if (currentClient.adPackage === "500") { adFee = 250; }
			else if (currentClient.adPackage === "1000") { adFee = 400; }
			else if (currentClient.adPackage === "0") { adFee = 0; }
		}

		return (
			<div id="pageFour" className="page">
				{/*
				<section>
					<div className="page-title">
						<h2>Authorization for Credit Card Use</h2>
					</div>
					<p>All information will remain confidential</p>

					<p>I authorize Mobile Marketing LLC to charge my monthly digital Velocity services to the credit card provided .
					I agree to pay for this purchase in accordance with the issuing bank cardholder agreement.
					I understand that invoices are to be paid directly to Mobile Marketing LLC.</p>

					<p>Velocity Powered by Mobile Marketing LLC invoices are eligible for reimbursement by Shaw up to a percentage of the balance you have accrued in your co-op account (percentages for reimbursement are set by Shaw, and have ranged from 50% to 100%).
					If you would like to claim your Shaw co-op funds for this expense, please log in to ShawNow.com, check your balance, and upload your Velocity invoice for reimbursement.
					Use of your co-op funds for Velocity is pre-approved by Shaw, so your credit will be issued to you typically within two to three days, but could take up to a week.</p>

					<div className="input-table-row">
						<InputRow label="I agree to the terms & conditions above" type="checkbox" property="contractCreditAuth" value={""} />
					</div>
					<p>If you prefer to pay your monthly digital Velocity invoices directly by bank transfer, please print, complete, and return the Authorization for Bank Transfer form to Mobile Marketing LLC. You can find this form on the Additional Documents page.</p>
				</section>
				*/}
				<div>
					<div className="page-title">
						<h2>Review Package Selection & Enter Payment Info</h2>
						<p>Provide your payment details to pay your deposit. You won’t be billed the remaining setup or monthly service fee(s) until completion of our onboarding process. We will contact you upon signup to onboard you into Velocity.</p>
					</div>
					<div className="check-out">
						<div className="order-summary">
							<h3>Review Your Package Selection</h3>
							<h3>Review Your Package Selection</h3>
							<div className="package">
								{(currentClient) && (
									(currentClient.servicePackage === "lite") && (
										<div className="blue">
											<div className="heading">
												<h2>Velocity Lite</h2>
											</div>
											<div className="pricing">
												<div className="setup"><span>$399</span> setup fee</div>
											</div>
										</div>
									)
								)}
								{(currentClient) && (
									(currentClient.servicePackage === "prime") && (
										<div className="orange">
											<div className="heading">
												<h2>Velocity Prime</h2>
											</div>
											<div className="pricing">
												<div className="setup"><span>$1,700</span> setup fee</div>
											</div>
										</div>
									)
								)}
								{(currentClient) && (
									(currentClient.servicePackage === "premium") && (
										<div className="purple">
											<div className="heading">
												<h2>Velocity Premium</h2>
											</div>
											<div className="pricing">
												<div className="setup"><span>$2,900</span> setup fee</div>
											</div>
										</div>
									)
								)}
								{(currentClient) && (
									(currentClient.servicePackage === "platinum") && (
										<div className="green">
											<div className="heading">
												<h2>Velocity Platinum</h2>
											</div>
											<div className="pricing">
												<div className="setup"><span>$3,500</span> setup fee</div>
											</div>
										</div>
									)
								)}
							</div>
							<div className="package">
								{(currentClient) && (
									(currentClient.adPackage === "0") && (
										<div className="orange">
											<div className="heading">
												<h2>
													Advertising / Opted Out
												</h2>
											</div>
											<div className="pricing">
												<div className="setup"><span>$0</span> setup fee</div>
											</div>
										</div>
									)
								)}
								{(currentClient) && (
									(currentClient.adPackage === "250") && (
										<div className="green">
											<div className="heading">
												<h2>
													Advertising
													<i className="fas fa-people-arrows"></i>
													<i className="fas fa-people-arrows"></i>
												</h2>
											</div>
											<div className="pricing">
												<div className="setup"><span>$100</span> setup fee</div>
											</div>
										</div>
									)
								)}
								{(currentClient) && (
									(currentClient.adPackage === "500") && (
										<div className="green">
											<div className="heading">
												<h2>
													Advertising
													<i className="fas fa-people-arrows"></i>
													<i className="fas fa-people-arrows"></i>
													<i className="fas fa-people-arrows"></i>
													<i className="fas fa-people-arrows"></i>
												</h2>
											</div>
											<div className="pricing">
												<div className="setup"><span>$250</span> setup fee</div>
											</div>
										</div>
									)
								)}
								{(currentClient) && (
									(currentClient.adPackage === "1000") && (
										<div className="green">
											<div className="heading">
												<h2>
													Advertising
													<i className="fas fa-people-arrows"></i>
													<i className="fas fa-people-arrows"></i>
													<i className="fas fa-people-arrows"></i>
													<i className="fas fa-people-arrows"></i>
													<i className="fas fa-people-arrows"></i>
													<i className="fas fa-people-arrows"></i>
												</h2>
											</div>
											<div className="pricing">
												<div className="setup"><span>$400</span> setup fee</div>
											</div>
										</div>
									)
								)}
							</div>
							<div className="package total">
								<div className="green">
									<div className="heading">
										<h2>Invoice Total</h2>
									</div>
									<div className="pricing">
										<div className="setup"><span>{numberToPrice(serviceFee + adFee)}</span></div>
									</div>
								</div>
							</div>
						</div>
						{(!transactionComplete) ? (
							<div className="cc-form">
								<h3>Payment Information</h3>
								<div className="paymentInformation">

									{(this.state.loadingClient) ? (
										<div className="loading">
											<i className="fas fa-spinner"></i>
										</div>
									) : null}
									<div className="cardholder-name">
										<input placeholder="Card holder name" type="text" property="name" id="cardName" />
									</div>
									<FormContainer
										environment="sandbox"
										onError={this.onErrorHandler}
										onSuccess={this.onSuccessHandler}
										amount={serviceFee + adFee}
										component={FormComponent}
										clientKey={authCreds.clientKey}
										apiLoginId={authCreds.apiLoginId} />

									<ToastContainer />
								</div>
							</div>
						) : (
							<div className="cc-form" style={{textAlign:"center"}}>
								<h3>Your Set Up Fee has been paid. Thank you for completing your onboarding!</h3>
								<p>Your Account Manager will be in touch shortly to schedule your kickoff meeting.</p>
							</div>
						)}
					</div>
				</div>
			</div>
		)
	}
}



export default StepFour;