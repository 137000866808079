import React from 'react';
import { TermsConditions } from '../../helpers/velocityContract';
import { InputRow } from '../../helpers/inputRow';

class StepThree extends React.Component {
	constructor(props) {
		super(props);

		this.selectPack = this.selectPack.bind(this);
		this.acceptConditions = this.acceptConditions.bind(this);
	}
	componentDidUpdate(prevProps) {
    	//console.log("did update");
    	try{
			if (this.props.currentClient && (this.props.currentClient !== prevProps.currentClient)) {
				this.setState({
					contractSignature: this.props.currentClient.contractSignature,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}
	selectPack(key, value) {
		var updatePack = this.props.updateClient;
		updatePack(key, value);

		document.querySelector(".pack").classList.remove("active");
		document.getElementById(value).classList.add("active");

	}
	acceptConditions(e) {
		var signature = e.target.value;
		var signed = false;
		var update = this.props.updateClient;

		if (signature.length > 0) {
			signed = true
		}

		update("contractSignature", signature);
		update("contractComplete", signed);
	}
	printElem(id) {
		var mywindow = window.open('', 'PRINT', 'height=400,width=600');

		mywindow.document.write('<html><head><title>' + document.title  + '</title>');
		mywindow.document.write('</head><body >');
		mywindow.document.write('<h1>' + document.title  + '</h1>');
		mywindow.document.write(document.getElementById(id).innerHTML);
		mywindow.document.write('</body></html>');

	    mywindow.document.close(); // necessary for IE >= 10
	    mywindow.focus(); // necessary for IE >= 10*/

	    mywindow.print();
	    mywindow.close();

	    return true;
	}

	render() {
		const currentClient = this.props.currentClient;

		console.log(currentClient);

		return (
			<div id="pageThree" className="page">
	            <div className="page-title">
					{(currentClient) && (
						(currentClient.servicePackage === "lite") ? (
							<h2>Your Digital Marketing Package</h2>
						) : (
							<React.Fragment>
								<h2>Select a Digital Marketing Package</h2>
								<p>Please select from Prime, Premium, or Platinum.</p>
							</React.Fragment>
						)
					)}
				</div>

				<div className="package">
					{(currentClient) && (
						<React.Fragment>
							<div className="blue">
								<div className="heading">
									<h2>Velocity<br/>Lite</h2>
									<p>100% Business Development Funds.</p>
									<p>Great for Shaw Web Studio transition and Shaw promotions.</p>
								</div>
								<div className="pricing">
									<div className="monthly"><span>$350</span>/month</div>
									<div className="setup"><span>$399</span> setup fee</div>
									{(currentClient) && (
										(currentClient.servicePackage === "lite") ? (
											<button id="lite" className="pack white active" onClick={() => this.selectPack("servicePackage", "lite")}>Select</button>
										) : (
											<button id="lite" className="pack white" onClick={() => this.selectPack("servicePackage", "lite")}>Select</button>
										)
									)}
								</div>
							</div>
							<div className="orange">
								<div className="heading">
									<h2>Velocity<br/>Prime</h2>
									<p>100% Business Development Funds</p>
									<p><em>Great for all Shaw catalogs and a single store location</em></p>
								</div>
								<div className="pricing">
									<div className="monthly"><span>$750</span>/month</div>
									<div className="setup"><span>$1,700</span> setup fee</div>
									{(currentClient) && (
										(currentClient.servicePackage === "prime") ? (
											<button id="prime" className="pack white active" onClick={() => this.selectPack("servicePackage", "prime")}>Select</button>
										) : (
											<button id="prime" className="pack white" onClick={() => this.selectPack("servicePackage", "prime")}>Select</button>
										)
									)}
								</div>
							</div>
							<div className="purple">
								<div className="heading">
									<h2>Velocity<br/>Premium</h2>
									<p>100% Business Development Funds</p>
									<p><em>Expanded Manufacturer catalogs and great for promoting other services</em></p>
								</div>
								<div className="pricing">
									<div className="monthly"><span>$1,150</span>/month</div>
									<div className="setup"><span>$2,900</span> setup fee</div>
									{(currentClient) && (
										(currentClient.servicePackage === "premium") ? (
											<button id="premium" className="pack white active" onClick={() => this.selectPack("servicePackage", "premium")}>Select</button>
										) : (
											<button id="premium" className="pack white" onClick={() => this.selectPack("servicePackage", "premium")}>Select</button>
										)
									)}
								</div>
							</div>
							<div className="green">
								<div className="heading">
									<h2>Velocity<br/>Platinum</h2>
									<p>100% Business Development Funds</p>
									<p><em>Ideal for those who want to maximize their site, presence, support multiple store locations, and much more</em></p>
								</div>
								<div className="pricing">
									<div className="monthly"><span>$1,700</span>/month</div>
									<div className="setup"><span>$3,500</span> setup fee</div>
									{(currentClient) && (
										(currentClient.servicePackage === "platinum") ? (
											<button id="platinum" className="pack white active" onClick={() => this.selectPack("servicePackage", "platinum")}>Select</button>
										) : (
											<button id="platinum" className="pack white" onClick={() => this.selectPack("servicePackage", "platinum")}>Select</button>
										)
									)}
								</div>
							</div>
						</React.Fragment>
					)}
						
				</div>

				<div className="page-title">
					<h2>Select an Advertising Package</h2>
					<p>Starting at just $250/month, we maximize your budget across Paid Search and Social. We recommend adding advertising to you solution but it can also be added after your new Velocity site launches.</p>
				</div>

				<div className="package">
					<div className="green">
						<div className="heading">
							<h2>
								<i className="fas fa-people-arrows"></i>
								<i className="fas fa-people-arrows"></i>
							</h2>
						</div>
						<div className="pricing">
							<div className="monthly"><span>$250</span>/month</div>
							<div className="setup"><span>$100</span> setup fee</div>
							{(currentClient) && (
								(currentClient.adPackage === "250") ? (
									<button id="250" className="pack white active" onClick={() => this.selectPack("adPackage", "250")}>Select</button>
								) : (
									<button id="250" className="pack white" onClick={() => this.selectPack("adPackage", "250")}>Select</button>
								)
							)}
						</div>
					</div>
					<div className="green">
						<div className="heading">
							<h2>
								<i className="fas fa-people-arrows"></i>
								<i className="fas fa-people-arrows"></i>
								<i className="fas fa-people-arrows"></i>
								<i className="fas fa-people-arrows"></i>
							</h2>
						</div>
						<div className="pricing">
							<div className="monthly"><span>$500</span>/month</div>
							<div className="setup"><span>$250</span> setup fee</div>
							{(currentClient) && (
								(currentClient.adPackage === "500") ? (
									<button id="500" className="pack white active" onClick={() => this.selectPack("adPackage", "500")}>Select</button>
								) : (
									<button id="500" className="pack white" onClick={() => this.selectPack("adPackage", "500")}>Select</button>
								)
							)}
						</div>
					</div>
					<div className="green">
						<div className="heading">
							<h2>
								<i className="fas fa-people-arrows"></i>
								<i className="fas fa-people-arrows"></i>
								<i className="fas fa-people-arrows"></i>
								<i className="fas fa-people-arrows"></i>
								<i className="fas fa-people-arrows"></i>
								<i className="fas fa-people-arrows"></i>
							</h2>
						</div>
						<div className="pricing">
							<div className="monthly"><span>$1,000+</span>/month</div>
							<div className="setup"><span>$400</span> setup fee</div>
							{(currentClient) && (
								(currentClient.adPackage === "1000") ? (
									<button id="1000" className="pack white active" onClick={() => this.selectPack("adPackage", "1000")}>Select</button>
								) : (
									<button id="1000" className="pack white" onClick={() => this.selectPack("adPackage", "1000")}>Select</button>
								)
							)}
						</div>
					</div>
				</div>
				<div className="ad-opt-out">
					<h4>I wish to opt out of Advertising at this time, or I would like to select and pay for my Advertising Package at a later date.</h4>
					{(currentClient) && (
						(currentClient.adPackage === "0") ? (
							<button id="0" className="pack white active" onClick={() => this.selectPack("adPackage", "0")}>Opt Out</button>
						) : (
							<button id="0" className="pack white" onClick={() => this.selectPack("adPackage", "0")}>Opt Out</button>
						)
					)}
				</div>

				<div className="terms-conditions">
					<div className="page-title">
						<h2>Velocity Contract</h2>
					</div>
					<button onClick={() => this.printElem("print-window")}>Print/Download Contract</button>
					<p><em>set printer destination to your desired printer to print a paper copy, or to "save as PDF" to download the contract.</em></p>
					<TermsConditions
						currentClient={currentClient}
					/>
					<h4>Contract Signature</h4>
					<div className="accept-conditions">
						<InputRow 
							label="*First & Last Name"
							description="*Signature Required: I am a qualified representative of the signing party and I hereby aknowledge and accept all of the terms and conditions of the contract above. I accept this as a valid digital signature of the above contract."
							type="text"
							property="contractSignature"
							value={currentClient?.contractSignature}
							onChange={this.acceptConditions}
							required
						/>
					</div>					
				</div>
	        </div>
		)
	}
}

export default StepThree;