
export const months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
export const mon = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

export const currentMonth = months[new Date().getMonth()];

export const errorMessage = {"message": "An Error Occured While Fetching Your Data, Please Try Again Shortly"};

export const testLoginExpires = () => {
	let expires = localStorage.getItem('expires');
	let token = localStorage.getItem('accessToken');
	let now = Date.now();

	if (expires < now) {
		// console.log("expired");
		window.location.reload();
	} else if (!token) {
		// console.log("no token");
		window.location.reload();
	} else {
		// console.log("valid");
	}
}