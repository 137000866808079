export const tokenParams = (user, pass) => {
	var params = {
		headers: {'Content-Type':'application/x-www-form-urlencoded'},
	    method: "POST",
	    referrer: "no-referrer",
	    body: 'grant_type=client_credentials&client_id='+user+'&client_secret='+pass,
	}
	return params;
}

export const basicGET = (accessToken) => {
	var params = {
		headers: {
			"authorization": "bearer" + accessToken,
		},
		method: "GET",
	}
	return params;
}

export const basicJSONPOST = (accessToken, body) => {
	var params = {
		headers: {
			"authorization": "bearer" + accessToken,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
		method: "POST",
	}
	return params;
}

export const basicJSONDELETE = (accessToken, body) => {
	var params = {
		headers: {
			"authorization": "bearer" + accessToken,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
		method: "DELETE",
	}
	return params;
}

export const basicFILEPOST = (accessToken, file, action) => {
	var params;

	if (action === "DELETE") {
		params = {
			headers: {
				"authorization": "bearer" + accessToken,
			},
			method: action,
		}
	} else {
		params = {
			headers: {
				"authorization": "bearer" + accessToken,
			},
			body: file,
			method: action,
		}
	}
	return params;
}

export const authorizeCaptureTransaction = (body) => {
	var params = {
		headers: {
			// "authorization": "bearer" + accessToken,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
		method: "POST",
	}
	return params;
}
