import React from 'react';

class StepFive extends React.Component {

	render() {
		return (
			<div id="pageFive" className="page">
	            <section>
		            <div>
			            <h2>Shaw Web Studio Authorization</h2>
			            <p>As an exclusive program for Shaw Flooring Network Retailers, Velocity web sites rely on integration to Shaw services like product catalogs and customer reviews. 
			            Retailers joining the Velocity program must authorize the linking of those services to their new Velocity web site.</p>
		 
						<div>
							<a className="link" target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSfBfZpkZIQEbar8l18ATeylFEdAqzVYyXAWOvTOmBpKB_CuHg/viewform">
								Visit Form <i className="fas fa-arrow-right"></i>
							</a>
						</div>
					</div>

					<div>
			            <h2>Authorization for Bank Transfer</h2>
						<p>Print, complete, and return the Authorization for Bank Transfer form to Mobile Marketing LLC.</p>
			            <p>This form Authorizes Mobile Marketing LLC. to charge monthly digital Velocity services to the Bank Account provided.</p>
		 
						<div>
							<a className="link" target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSfBfZpkZIQEbar8l18ATeylFEdAqzVYyXAWOvTOmBpKB_CuHg/viewform">
								Download PDF <i className="fas fa-arrow-down"></i>
							</a>
						</div>
						<div>
							<a className="link" target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSfBfZpkZIQEbar8l18ATeylFEdAqzVYyXAWOvTOmBpKB_CuHg/viewform">
								Print PDF <i className="fas fa-print"></i>
							</a>
						</div>
					</div>
				</section>
	        </div>
		)
	}
}

export default StepFive;