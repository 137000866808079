import React from 'react';
import { InputRow } from '../../helpers/inputRow';


class StepOne extends React.Component {
	constructor(props) {
		super(props);
		this.updateClient = this.updateClient.bind(this);
		this.updateContact = this.updateContact.bind(this);
	}

	handleContact(action, id) {
		var addContact = this.props.addContact;
		var delContact = this.props.delContact;

		if (action === "add") {
			addContact(id);
		} else if (action === "del") {
			delContact(id);
		}
	}
	updateClient(e) {
		var update = this.props.updateClient;
		var key = e.target.name;
		var value = e.target.value;

		update(key, value);
	}
	updateContact(e) {
		var update = this.props.updateContact;
		var id = e.target.attributes.customdata.value;
		id = parseInt(id)
		var key = e.target.name;
		var value = e.target.value;

		update(id, key, value);
	}
	render() {
		var billingContact = [];
		var businessContacts = [];
		let owner;
		const currentClient = this.props.currentClient;

		if (this.props.contacts !== null && this.props.contacts.length>0) {
			owner = this.props.contacts.filter(function( obj ) {
                return obj.type === "Owner";
            });
		 	billingContact = this.props.contacts.filter(function( obj ) {
                return obj.type === "billing";
            });
            businessContacts = this.props.contacts.filter(function( obj ) {
                return (obj.type !== "billing" && obj.type !== "Owner");
            });
		}
		// console.log(owner)

		return (
			<div id="pageOne" className="page">
				<div className="page-title">
					<h2>Business & Contact Information</h2>
					<p>Please provide some basic Business details and Contacts to get started.</p>
				</div>

	            <div id="client" className="input-group col-one">
	            	{(currentClient) ? (
							<React.Fragment>
	            				<InputRow required label="* Doing Business As Name (DBA)" type="text" id="business-name" value={currentClient.name} property="name" onChange={this.updateClient} />
								<InputRow required label="* Business Tax Name (W9)" type="text" id="business-w9Name" value={currentClient.w9Name} property="w9Name" onChange={this.updateClient} />
							</React.Fragment>
	            		) : (
							<React.Fragment>
	            				<InputRow required label="* Doing Business As Name (DBA)" type="text" id="business-name" property="name" onChange={this.updateClient} />
								<InputRow required label="* Business Tax Name (W9)" type="text" id="business-w9Name" property="w9Name" onChange={this.updateClient} />
							</React.Fragment>
	            		)
	            	}
					<InputRow label="Shaw Retailer Account Number (IF AVAILABLE)" type="text" id="shawAccount" value={currentClient.shawAccount} property="shawAccount" onChange={this.updateClient} description="*Used to populate catalogs from this manufacturer." />
	            </div>

	            <h3>Business Owner</h3>
	            <div id="owner" className="input-group col-two">
	            	{(owner) && (
	            		owner.map(contact =>
		            		<React.Fragment key={contact.id}>
			            		<em>* Required</em>
			            		<div className={"sub-group contact-"+contact.id} >
					            	<input type="hidden" name="id" value={contact.id} />
					            	<input type="hidden" name="clientCode" value={contact.clientCode} />

					            	<InputRow required onChange={this.updateContact} data={contact.id} label="* First Name" type="text" id={"fName-"+contact.id} property="firstName" value={contact.firstName} />
					            	<InputRow required onChange={this.updateContact} data={contact.id} label="* Last Name" type="text" id={"lName-"+contact.id} property="lastName" value={contact.lastName} />
					            	<InputRow required onChange={this.updateContact} data={contact.id} label="* Email" type="text" id={"email-"+contact.id} property="email" value={contact.email} />
					            	<InputRow required onChange={this.updateContact} data={contact.id} label="* Business Phone" type="text" id={"phone"+contact.id} property="phone" value={contact.phone} />
				            		<InputRow onChange={this.updateContact} data={contact.id} label="Mobile Phone" type="text" id={"mobilePhone"+contact.id} property="mobilePhone" value={contact.mobilePhone} />
					            </div>
				            </React.Fragment>
				        )
		            )}
	            </div>

	            <h3>Primary Accountant/Billing Contact</h3>
	            <div id="billing-contact" className="input-group col-two">
	            	{(billingContact) && (
	            		billingContact.map(contact =>
	            			<React.Fragment key={contact.id}>
	            			<em>* Required</em>
	            			<div className={"sub-group contact-"+contact.id}>
				            	<InputRow required onChange={this.updateContact} data={contact.id} label="* First Name" type="text" id={"fName-"+contact.id} property="firstName" value={contact.firstName} />
				            	<InputRow required onChange={this.updateContact} data={contact.id} label="* Last Name" type="text" id={"lName-"+contact.id} property="lastName" value={contact.lastName} />
				            	<InputRow required onChange={this.updateContact} data={contact.id} label="* Email" type="text" id={"email-"+contact.id} property="email" value={contact.email} />
				            	<InputRow required onChange={this.updateContact} data={contact.id} label="* Business Phone" type="text" id={"phone"+contact.id} property="phone" value={contact.phone} />
			            		<InputRow onChange={this.updateContact} data={contact.id} label="Mobile Phone" type="text" id={"mobilePhone"+contact.id} property="mobilePhone" value={contact.mobilePhone} />
				            </div>
				            </React.Fragment>
				        )
            		)}
	            </div>

	            <h3>Additional Contacts</h3>
	            <div id="contacts" className="input-group col-two">
					{(businessContacts) && (
	            		businessContacts.map(
	            			(contact) => (contact.type !== "billing") && (
		            			<div className={"sub-group contact-"+contact.id} key={contact.id} >
					            	<input type="hidden" name="id" value={contact.id} />
					            	<input type="hidden" name="clientCode" value={contact.clientCode} />

					            	<InputRow onChange={this.updateContact} data={contact.id} label="First Name" type="text" id={"fName-"+contact.id} property="firstName" value={contact.firstName} />
					            	<InputRow onChange={this.updateContact} data={contact.id} label="Last Name" type="text" id={"lName-"+contact.id} property="lastName" value={contact.lastName} />
					            	<InputRow onChange={this.updateContact} data={contact.id} label="Job Title" type="text" id={"type-"+contact.id} property="type"  value={contact.type}/>
					            	<InputRow onChange={this.updateContact} data={contact.id} label="Email" type="text" id={"email-"+contact.id} property="email" value={contact.email} />
					            	<InputRow onChange={this.updateContact} data={contact.id} label="Business Phone" type="text" id={"phone"+contact.id} property="phone" value={contact.phone} />
				            		<InputRow onChange={this.updateContact} data={contact.id} label="Mobile Phone" type="text" id={"mobilePhone"+contact.id} property="mobilePhone" value={contact.mobilePhone} />
				            		<button className="link" onClick={() => this.handleContact("del", contact.id)} >Delete</button>
					            </div>
				            )
				        )
            		)}
	            	<div>
	            		<button className="link" onClick={() => this.handleContact("add")} >Add Contact +</button>
	            	</div>
	            </div>

	        </div>
		)
	}
}

export default StepOne;