import React from 'react';
import step1Yellow from '../../images/step1-yellow.png';
import step2Yellow from '../../images/step2-yellow.png';
import step3Yellow from '../../images/step3-yellow.png';
import step4Yellow from '../../images/step4-yellow.png';
import step5Yellow from '../../images/step5-yellow.png';
import step2Blue from '../../images/step2-blue.png';
import step3Blue from '../../images/step3-blue.png';
import step4Blue from '../../images/step4-blue.png';
import step5Blue from '../../images/step5-blue.png';

export class Navigation extends React.Component {
	render() {
		const currentStep = this.props.currentStep;
		// console.log(currentStep);
		
		return (
			<div className="step-progress">
				<div className="step" id="step1">
					<img src={step1Yellow} alt="Step 1" />
					<div className="status-bar green"></div>
					<small>Get Started</small>
				</div>
				<div className="step" id="step2">
					{(currentStep >= 2) ? (
						<React.Fragment>
							<img src={step2Yellow} alt="Step 2" />
							<div className="status-bar green"></div>
						</React.Fragment>
					) : (
						<React.Fragment>
							<img src={step2Blue} alt="Step 2" />
							<div className="status-bar blue"></div>
						</React.Fragment>
					)}
					<small>Business Details</small>
				</div>
				<div className="step" id="step3">
					{(currentStep >= 3) ? (
						<React.Fragment>
							<img src={step3Yellow} alt="Step 3" />
							<div className="status-bar green"></div>
						</React.Fragment>
					) : (
						<React.Fragment>
							<img src={step3Blue} alt="Step 3" />
							<div className="status-bar blue"></div>
						</React.Fragment>
					)}
					<small>Marketing & Advertising Packages</small>
				</div>
				<div className="step" id="step4">
					{(currentStep >= 4) ? (
						<React.Fragment>
							<img src={step4Yellow} alt="Step 4" />
							<div className="status-bar green"></div>
						</React.Fragment>
					) : (
						<React.Fragment>
							<img src={step4Blue} alt="Step 4" />
							<div className="status-bar blue"></div>
						</React.Fragment>
					)}
					<small>Payment & Confirmation</small>
				</div>
				<div className="step" id="step5">
					{(currentStep >= 5) ? (
						<React.Fragment>
							<img src={step5Yellow} alt="Step 5" />
							<div className="status-bar green"></div>
						</React.Fragment>
					) : (
						<React.Fragment>
							<img src={step5Blue} alt="Step 5" />
							<div className="status-bar blue"></div>
						</React.Fragment>
					)}
					<small>Additional Documents</small>
				</div>
			</div>
		)
	}
}

export default Navigation;