import {basicGET, basicJSONPOST, basicFILEPOST, basicJSONDELETE, authorizeCaptureTransaction} from '../../api/fetchHelpers'


//----------------------------------------ACTUAL DATA HANDLE FUNCTIONS------------------------------------------------------------//


export const getClientData = (endpoint, clientCode, accessToken) => {
    return fetch(endpoint + clientCode, basicGET(accessToken))
    .then(response => {
        if (response.ok) { return response.json(); } else { throw new Error('Something went wrong fetching the client data ...'); }
    })
    .then(data => { console.log(data); return data })
    .catch(error => console.log(error));
}
export const saveClientData = (endpoint, currentClient, accessToken) => {
    return fetch(endpoint, basicJSONPOST(accessToken, currentClient))
    .then(response => {
        if (response.ok) { return response.json(); } else { throw new Error('Something went wrong ...'); }
    })
    .then(data => { return data; })
    .catch(error => console.log(error));
}

export const getClientCredentials = (endpoint, clientCode, accessToken) => {
    return fetch(endpoint + clientCode + "/credential", basicGET(accessToken) )
    .then(response => {
        if (response.ok) { return response.json(); } else { throw new Error('Something went wrong ...'); }
    })
    .then(data => { return data; })
    .catch(error => console.log(error));
}

export const authCaptureTransation = (endpoint, currentClient) => {
    return fetch(endpoint, authorizeCaptureTransaction(currentClient))
    .then(response => {
        if (response.ok) { return response.json(); } else { throw new Error('Something went wrong ...'); }
    })
    .then(data => { return data; })
    .catch(error => console.log(error));
}
export const getTransactions = (endpoint, clientCode, accessToken) => {
    return fetch(endpoint + "transactions/" + clientCode, basicGET(accessToken) )
    .then(response => {
        if (response.ok) { return response.json(); } else { throw new Error('Something went wrong ...'); }
    })
    .then(data => { return data; })
    .catch(error => console.log(error));
}


// save contacts, locations, and credentials
export const save = (endpoint, accessToken, postBody) => {
    return fetch(endpoint, basicJSONPOST(accessToken, postBody))
    .then(response => {
        if (response.ok) { return response.json(); } else { throw new Error('Something went wrong ...'); }
    })
    .then(data => { return data; })
    .catch(error => console.log(error));
}

export const deleteRecord = (endpoint, accessToken) => {
    var postBody = {}
    return fetch(endpoint, basicJSONDELETE(accessToken, postBody))
    .then(response => {
        if (response.ok) { return response.json(); } else { throw new Error('Something went wrong ...'); }
    })
    .then(data => { return data; })
    .catch(error => console.log(error));
}

export const sendAlert = (endpoint, body, accessToken) => {
    return fetch(endpoint, basicJSONPOST(accessToken, body))
    .then(response => {
        if (response.ok) { return response.json(); } else { throw new Error('Something went wrong ...'); }
    })
    .then(data => { return data; })
    .catch(error => console.log(error));
}

export async function addImage(endpoint, image, accessToken) {
    console.log(endpoint, image)
	const response = await fetch(endpoint, basicFILEPOST(accessToken, image, "POST"));
    if (!response.ok) {
        const message = "An error has occured: "+response.status;
        throw new Error(message);
    }
    const data = await response.json();
    return data;
}

export async function deleteImage(endpoint, accessToken) {
	const response = await fetch(endpoint, basicJSONDELETE(accessToken, {}));
    if (!response.ok) {
        const message = "An error has occured: "+response.status;
        throw new Error(message);
    }
    const data = await response.json();
    return data;
}






