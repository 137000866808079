import React from 'react';

class Footer extends React.Component {
	render() {
		const currentStep = parseInt(this.props.currentStep);
		const currentClient = this.props.currentClient;		
		var transactionComplete = this.props.transactionComplete;
		
		var hasNames = false;
		// console.log(currentClient);
		if (currentClient.name && currentClient.w9Name) {
			hasNames = true;
		}


		var contactsComplete = false;
		const contacts = this.props.contacts;
		if (contacts) {
			var owner = contacts.filter(function( obj ) {
	            return obj.type === "Owner";
	        });
		 	var bill = contacts.filter(function( obj ) {
	            return obj.type === "billing";
	        });
			// console.log(owner.length > 0, bill.length > 0)
			if (owner.length > 0 && bill.length > 0) {
				if (owner[0].firstName &&
					owner[0].lastName &&
					owner[0].email &&
					owner[0].phone &&
					bill[0].firstName &&
					bill[0].lastName &&
					bill[0].email &&
					bill[0].phone
				) {
					contactsComplete = true;
				}
			}
		}

		var billingLocationComplete = false;
		var storeLocationComplete = false;
		const locations = this.props.locations;
		if (locations) {
			var billingLocation = locations.filter(function( obj ) {
	            return obj.type === "billing";
	        });
			var storeLocation = locations.filter(function( obj ) {
	            return obj.type === "store";
	        });
			if (billingLocation.length) {
				if (
					billingLocation[0].name &&
					billingLocation[0].address &&
					billingLocation[0].city &&
					billingLocation[0].state &&
					billingLocation[0].country &&
					billingLocation[0].postalCode &&
					billingLocation[0].phone
				) {
					billingLocationComplete = true;
				}
			}
			if (storeLocation.length) {
				if (
					storeLocation[0].name &&
					storeLocation[0].address &&
					storeLocation[0].city &&
					storeLocation[0].state &&
					storeLocation[0].country &&
					storeLocation[0].postalCode &&
					storeLocation[0].phone
				) {
					storeLocationComplete = true;
				}	
			}
		}
		

		return(
			<React.Fragment>
				<div id="footer-banner">
					<div id="step-navigation">
						<div className="help-number"><h2>Questions?</h2> <span className="large-display">Call <a href="tel:4842554227">(484) 255-4227</a></span></div>
						{(currentStep > 1) && (
							<button className="link" onClick={() => this.props.back(currentStep - 1)}>Back</button>
						)}

						{(currentStep === 1) && (
							(hasNames && contactsComplete) ? (
								<button className="green" onClick={() => this.props.next(currentStep + 1)}>Next</button>
							) : (
								<button className="green disabled">Next</button>
							)
						)}

						{(currentStep === 2) && (
							(billingLocationComplete && storeLocationComplete) ? (
								<button className="green" onClick={() => this.props.next(currentStep + 1)}>Next</button>
							) : (
								<button className="green disabled">Next</button>
							)
						)}
						
						{(currentStep === 3) && (
							(currentClient) && (
								(currentClient.contractComplete === true) ? (
									<button className="green" onClick={() => this.props.next(currentStep + 1)}>Next</button>
								) : (
									<button className="green disabled">Next</button>
								)
							)
						)}

						{(currentStep === 4) && (
							(transactionComplete) ? (
								<button className="green" onClick={() => this.props.next(currentStep + 1)}>Next</button>
							) : (
								<button className="green disabled">Next</button>
							)
						)}

					</div>
				</div>
				<div id="footer-save-banner">
					<p><em>* Green input fields are required in order to continue to the next step.</em></p>
					<p><em>Your progress will be automatically saved each time you click the "Back" or "Next" progress buttons, or when you click the "Save & Exit Portal" button.</em></p>
					<p><em>You may save your progress and return at a later time to complete your onboarding.</em></p>
				</div>
				<footer>
					<p>Powered by <a href="https://mobile-marketing.agency" target="_blank" rel="noopener noreferrer">Mobile Marketing LLC.</a></p>
					<small>©{(new Date().getFullYear())} Mobile Marketing, LLC. All rights reserved.</small>
				</footer>
			</React.Fragment>
		);
	}

}

export default Footer;