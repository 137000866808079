import React from 'react';
import { isChecked, valueEmpty, isSelected, formatMonth } from './uiFunctions';

export class InputRow extends React.Component {

	render() {
		let label = this.props.label;
		let placeholder = this.props.placeholder;
		let type = this.props.type;
		let value = this.props.value;
		let property = this.props.property;
		let disabled = this.props.disabled;
		let required = this.props.required;
		let options = this.props.options;
		let description = this.props.description;
		let data = this.props.data;
		let rows = this.props.rows;
		let id = this.props.id;
		let onChange = this.props.onChange;
		let max;

		if (type === "date") {
			var year, month, day;
		    var today = new Date();

		    year = today.getFullYear();
		    month = formatMonth( today.getMonth()+1 );
		    day = formatMonth( today.getDate() );
		    var nowEndDate = year+"-"+month+"-"+day;
			max = nowEndDate;
		}


		return(
			<div className="input-row">

				{(label) && (
					<label htmlFor={property}>{label}</label>
				)}

				{(type === "checkbox" && !disabled) && (
					<div>
						<input type={type} name={property} defaultChecked={isChecked(value)} placeholder={placeholder || label} id={id} onClick={onChange} />
					</div>
				)}
				{(type === "checkbox" && disabled) && (
					<div>
						<input type={type} name={property} defaultChecked={isChecked(value)} placeholder={placeholder || label} id={id} disabled />
					</div>
				)}

				{((type === "date" || type === "number" || type === "color" || type === "password") && !disabled) && (
					<div>
						<input type={type} name={property} defaultValue={valueEmpty(value)} id={id} onChange={onChange} placeholder={placeholder || label} max={max} required={required} />
					</div>
				)}
				{((type === "date" || type === "number" || type === "color" || type === "password") && disabled) && (
					<div>
						<input type={type} name={property} defaultValue={valueEmpty(value)} id={id} onChange={onChange} placeholder={placeholder || label} max={max} required={required} disabled />
					</div>
				)}

				{(type === "text" && !disabled) && (
					<div>
						<input
							type={type}
							name={property}
							defaultValue={valueEmpty(value)}
							placeholder={placeholder || label}
							id={id}
							onChange={onChange}
							customdata={data}
							required={required}
						/>
						{(description) && (
							<p>{description}</p>
						)}
					</div>
				)}
				{(type === "text" && disabled) && (
					<input
						type={type}
						name={property}
						defaultValue={valueEmpty(value)}
						placeholder={placeholder || label}
						id={id}
						disabled
					/>
				)}

				{(type === "textarea") && (
					<div>
						<textarea
							name={property}
							defaultValue={valueEmpty(value)}
							placeholder={placeholder || label}
							rows={rows}
							id={id}
							required={required}
						></textarea>
					</div>
				)}

				{(type === "select" && !disabled) && (
					<div className="select">
						<select id={id} name={property} onChange={onChange} required={required}>
							<option defaultValue selected disabled>Select...</option>
							{options.map(option => 
								<option
									key={option.value} value={option.value} selected={isSelected(option.value, value)}>{option.name}</option>
							)}
						</select>
						<div className="select_arrow"></div>
					</div>
				)}
				{(type === "select" && disabled) && (
					<div className="select">
						<select id={id} name={property} disabled>
							<option defaultValue disabled>Select...</option>
							{options.map(option => 
								<option
									key={option.value} value={option.value} selected={isSelected(option.value, value)}>{option.name}</option>
							)}
						</select>
					</div>
				)}
				{(type === "file" && !disabled) && (
					<div className="file">
						<input type={type} name={property} id={id} onChange={onChange} filename={value} />
						{(value) && (<React.Fragment>
							<img src={"https://"+value} alt={description} id="img-logo" />
						</React.Fragment>)}
					</div>
				)}
				{(type === "file" && disabled) && (
					<div className="file">
						<input type={type} name={property} id={id} onChange={onChange} filename={value} disabled />
						{(value) && (<React.Fragment>
							<img src={"https://"+value} alt={description} id="img-logo" />
						</React.Fragment>)}
					</div>
				)}

			</div>
		)
	}
}