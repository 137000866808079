import React from 'react';

import { InputRow } from './inputRow';


export class LoginForm extends React.Component {
    constructor(props){
        super(props);
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event){
        if(event.keyCode === 13) {
            //Do whatever when esc is pressed
            // console.log("enter was hit");
            this.props.submit();
        }
    }
    componentDidMount(){
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
    }

	render() {
		const props = this.props;
		return(
			<form id="login-form">
                <h5>Login</h5>
                {(props.clientCodes.length > 1) ? (
                    <React.Fragment>
                        <p>Please Select An Account To Work On.</p>
                        <InputRow
                            type="select"
                            onChange={props.setClient}
                            property="setClient"
                            options={props.clientCodes}
                            id="setClient"
                        />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <p className="login-messages"></p>
                        <InputRow placeholder="Email" type="text" property="email" id="email" />
                        <InputRow placeholder="Password" type="password" property="password" id="password" />
                        <input type="button" className="button green" onClick={props.submit} value="Log In" />

                        <p className="forgot-link" onClick={() => props.showReset("forgot")}>Forgot/change my password</p>
                    </React.Fragment>
                )}
            </form>
		)
	}
}
export class ConfirmLoginForm extends React.Component {
    goToInsight() {
        window.location='https://insight.mobile-marketing.agency';
    }
    render() {
        const props = this.props;
        return(
            <form id="login-form">
                <p className="login-messages">It looks like your onboarding is already complete. Would you like to login anyway and review your information, or would you like to go to your Insight Portal?</p>
                
                <input type="button" className="button green" onClick={props.confirm} value="Log In Anyway" />
                <br/>
                <input type="button" className="button green" onClick={this.goToInsight} value="Visit My Insight Portal" />
            </form>
        )
    }
}

export class ForgotPassForm extends React.Component {
	render() {
		const props = this.props;
		return(
			<form id="forgot-pass">
                <h3>Forgot / Change My Password</h3>
                <p className="forgot-messages"></p>
                <InputRow placeholder="Email" type="text" property="email" id="forgot-email" />
                <input type="button" onClick={() => props.submit} className="button green" value="Send Password Reset Email" />

                <p className="forgot-link" onClick={() => props.showReset("login")}>Back To Login</p>
            </form>
		)
	}
}

export class PassResetForm extends React.Component {
	render() {
		const props = this.props;
		return(
			<form id="reset-form">
                <h3>Reset My Password</h3>
                <p className="reset-messages"></p>
                <InputRow placeholder="Email" type="text" property="email" id="reset-email" value={props.getUrlParameter("user")} />
                <input type="hidden" id="reset-noonce" value={props.getUrlParameter("n")} />
                <em>Passwords must be at least 10 characters long.</em>
                <InputRow placeholder="New Password" type="password" property="password" id="reset-password" />
                <InputRow placeholder="Confirm New Password" type="password" property="confirm-password" id="reset-confirm-password" onChange={() => props.validatePassword("reset-password", "reset-confirm-password", "confirm-pass-status")} />
                <div id="confirm-pass-status" className="fail"></div>

                <input type="button" onClick={() => props.submit} id="reset-submit" className="button green" value="Reset Password" />
            </form>
		)
	}
}


			


			