import React from 'react';
import { InputRow } from '../../helpers/inputRow';

class StepTwo extends React.Component {
	constructor(props) {
		super(props);
		this.updateLocation = this.updateLocation.bind(this);
		this.updateCredential = this.updateCredential.bind(this);
		this.saveImage = this.saveImage.bind(this);
	}
	saveImage() {
		var logoInput = document.getElementById("brand-logo");
		var logo = new FormData();

		logo.append('logo', logoInput.files[0]);
		console.log(logo);

		var upload = this.props.addImage;
		upload(logo);
	}
	deleteImage() {
		console.log("delete image");
		var remove = this.props.removeImage;
		remove();
	}
	updateClient(e) {
		var update = this.props.updateClient;
		var key = e.target.name;
		var value = e.target.value;

		update(key, value);
	}
	handleLocation(action, id) {
		var addLocation = this.props.addLocation;
		var delLocation = this.props.delLocation;

		if (action === "add") {
			addLocation();
		} else if (action === "del") {
			delLocation(id);
		}
	}
	updateLocation(e) {
		var update = this.props.updateLocation;
		var id = e.target.attributes.customdata.value;
		id = parseInt(id)
		var key = e.target.name;
		var value = e.target.value;

		update(id, key, value);
	}

	handleCredential(type, action, id) {
		var addCredential = this.props.addCredential;
		var delCredential = this.props.delCredential;

		var select = document.getElementById(type+"-select").selectedOptions[0];
		var group = select.getAttribute("group");
		var credType = select.getAttribute("type");
		var pltfrm = select.getAttribute("platform");

		if (action === "add") {
			addCredential(group, credType, pltfrm);
		} else if (action === "del") {
			delCredential(id);
		}
	}
	updateCredential(e) {
		var update = this.props.updateCredential;
		var id = e.target.attributes.customdata.value;
		id = parseInt(id)
		var key = e.target.name;
		var value = e.target.value;

		update(id, key, value);
		update(id, "active", true);
	}


	render() {
		const credentials = this.props.credentials;
		const currentClient = this.props.currentClient;
		// console.log(credentials);

		let billingStore;
		var locations = [];

		if (this.props.locations !== null && this.props.locations.length>0) {
			billingStore = this.props.locations.filter(function( obj ) {
                return obj.type === "billing";
            });
		 	locations = this.props.locations.filter(function( obj ) {
                return obj.type === "store";
            });
		 }

		return (
			<div id="pageTwo" className="page">
				<div className="page-title">
					<h2>Business Information</h2>
					<p>Please provide some basic Business details like store locations, social media links, and other connected services.
					Information provided will be used to populate your Velocity website.</p>
				</div>

				<h3>Branding</h3>
				<div id="branding-logo" className="input-group">
					<em>Max Image Size is 1MB, Required file types are .jpg or .png</em>
					{(currentClient.logo) ? (
						<React.Fragment>
							<InputRow type="file" label="Logo" property="logo" id="brand-logo" value={currentClient.logo} onChange={this.saveImage} description="Max Image Size is 1MB, Required file types are .jpg or .png" disabled />
							<button className="link" onClick={() => this.deleteImage()}>Delete</button>
						</React.Fragment>
					) : (
						<React.Fragment>
							<InputRow type="file" label="Logo" property="logo" id="brand-logo" value={currentClient.logo} onChange={this.saveImage} description="Max Image Size is 1MB, Required file types are .jpg or .png" />
						</React.Fragment>
					)}
				</div>

				<h3>Social Media Accounts</h3>
	            <div id="social-credential" className="input-group">
					<div className="new-credential">
						<div className="select">
							<select name="credential-select" id="social-select">
								<option type="fb" group="social" platform="facebook">Facebook</option>
								<option type="insta" group="social" platform="instagram">Instagram</option>
								<option type="pin" group="social" platform="pinterest">Pinterest</option>
								<option type="yt" group="social" platform="youTube">YouTube</option>
								<option type="ylp" group="social" platform="yelp">Yelp</option>

								{/* <option type="glbs" group="social" platform="googleBusiness">Google Business</option> */}
								{/* <option type="glpl" group="social" platform="googlePlus">Google Plus</option> */}
								{/* <option type="hmadv" group="social" platform="homeAdvisor">Home Advisor</option> */}
								{/* <option type="hzz" group="social" platform="houzz">Houzz</option> */}
								{/* <option type="lnkd" group="social" platform="linkedin">LinkedIn</option> */}
								{/* <option type="twit" group="social" platform="twitter">Twitter</option> */}
								{/* <option type="anglst" group="social" platform="angiesList">Angies List</option> */}
							</select>
							<div className="select_arrow"></div>
						</div>
						<button className="link " onClick={() => this.handleCredential("social", "add")} >Add Social Account +</button>
					</div>
					<div>
						{(credentials) && (
							credentials.map((cred) =>
								(cred.group === "social") && (
									<div className="social-link" key={cred.id}>
										{(cred.type === "anglst") && ( <h4>Angies List</h4> )}
										{(cred.type === "fb") && ( <h4>Facebook</h4> )}
										{(cred.type === "glbs") && ( <h4>Google Business</h4> )}
										{(cred.type === "hmadv") && ( <h4>Home Advisor</h4> )}
										{(cred.type === "hzz") && ( <h4>Houzz</h4> )}
										{(cred.type === "insta") && ( <h4>Instagram</h4> )}
										{(cred.type === "lnkd") && ( <h4>LinkedIn</h4> )}
										{(cred.type === "pin") && ( <h4>Pinterest</h4> )}
										{(cred.type === "twit") && ( <h4>Twitter</h4> )}
										{(cred.type === "ylp") && ( <h4>Yelp</h4> )}
										{(cred.type === "yt") && ( <h4>YouTube</h4> )}
										<InputRow onChange={this.updateCredential} data={cred.id} label="URL" type="text" value={cred.url} property="url" />
										<button className="link" onClick={() => this.handleCredential("social", "del", cred.id)} >Delete</button>
									</div>
								)
							)
						)}
					</div>
				</div>

				<h3>Financing Partners</h3>		
				<div id="financing-links" className="input-group">
					<div>
						<p>Please add the “Apply Online” link for your financing partner(s):</p>
						<InputRow onChange={this.updateClient} label="Synchrony" type="text" value={currentClient.financeSync} property="financeSync" placeholder="https://www.mysynchrony.com/......" />
						<InputRow onChange={this.updateClient} label="Wells Fargo" type="text" value={currentClient.financeWF} property="financeWF" placeholder="https://retailservices.wellsfargo.com/......" />
						<em>*This is the URL the financing company provides you to add to your website so customers can apply for financing (and you get credit for their sign-up). If you don’t have this information, please leave blank.</em>
					</div>
				</div>

				<h3>Store Retail Locations</h3>
	            <div id="store-locations" className="input-group">
					<em>*Required: At least 1 store retail location.</em>
		            {(locations) && (
		            	locations.map((store) =>
		            		<div className="sub-group" key={store.id}>
			            		<div className="col-one">
			            			<InputRow required onChange={this.updateLocation} data={store.id} label="* Store Name" type="text" property="name" value={store.name} />
			            		</div>
			            		<h4>Store Address</h4>
			            		<div className="col-two">
									<InputRow required onChange={this.updateLocation} data={store.id} label="* Street Address" type="text" property="address" value={store.address} />
									<InputRow required onChange={this.updateLocation} data={store.id} label="* City" type="text" property="city" value={store.city} />
									<InputRow required onChange={this.updateLocation} data={store.id} label="* State" type="text" property="state" value={store.state} />
									<InputRow required onChange={this.updateLocation} data={store.id} label="* Postal Code" type="text" property="postalCode" value={store.postalCode} />
									<InputRow required
										onChange={this.updateLocation} data={store.id}
										label="* Country"
										type="select"
										value={store.country}
										property="country"
										id="country"
										hideSelectOption="true"
										options={[
										   { "name": "USA", "value": "US"},
										   { "name": "Canada", "value": "CA"}
										]}  
									/>
								</div>
								<h4>Contact Information</h4>
								<div className="col-two">
									<InputRow required onChange={this.updateLocation} data={store.id} label="* Store Phone" type="text"  property="phone" value={store.phone} />
									<InputRow onChange={this.updateLocation} data={store.id} label="Store Fax" type="text"  property="fax" value={store.fax} />
								</div>
								<h4>Store Hours</h4>
								<p>Format like "9AM-5PM" or "Closed"</p>
								<div className="col-four">
									<InputRow onChange={this.updateLocation} data={store.id} label="Monday" type="text" value={store.monday} property="monday" />
									<InputRow onChange={this.updateLocation} data={store.id} label="Tuesday" type="text" value={store.tuesday} property="tuesday" />
									<InputRow onChange={this.updateLocation} data={store.id} label="Wednesday" type="text" value={store.wednesday} property="wednesday" />
									<InputRow onChange={this.updateLocation} data={store.id} label="Thursday" type="text" value={store.thursday} property="thursday" />
								</div>
								<div className="col-four">
									<InputRow onChange={this.updateLocation} data={store.id} label="Friday" type="text" value={store.friday} property="friday" />
									<InputRow onChange={this.updateLocation} data={store.id} label="Saturday" type="text" value={store.saturday} property="saturday" />
									<InputRow onChange={this.updateLocation} data={store.id} label="Sunday" type="text" value={store.sunday} property="sunday" />
								</div>
								<button className="link" onClick={() => this.handleLocation("del", store.id)} >Delete</button>
							</div>
	            		)
					)}
					<div>
	            		<button className="link" onClick={() => this.handleLocation("add")} >Add New Retail Store +</button>
	            	</div>
	            </div>

				<h3>Account Billing Address</h3>
				<div id="billing-location" className="input-group">
					<em>*Required: Address information for account billing.</em>
					{(billingStore) && (
		            	billingStore.map((store) =>
		            		<div className="sub-group" key={store.id}>
		            			<div className="col-one">
			            			<InputRow required onChange={this.updateLocation} data={store.id} label="* Billing Location Name" type="text" property="name" value={store.name} />
			            		</div>
			            		<h4>Address</h4>
			            		<div className="col-two">
									<InputRow required onChange={this.updateLocation} data={store.id} label="* Street Address" type="text" property="address" value={store.address} />
									<InputRow required onChange={this.updateLocation} data={store.id} label="* City" type="text" property="city" value={store.city} />
									<InputRow required onChange={this.updateLocation} data={store.id} label="* State" type="text" property="state" value={store.state} />
									<InputRow required onChange={this.updateLocation} data={store.id} label="* Postal Code" type="text" property="postalCode" value={store.postalCode} />
									<InputRow required
										onChange={this.updateLocation} data={store.id}
										label="* Country"
										type="select"
										value={store.country}
										property="country"
										id="country"
										hideSelectOption="true"
										options={[
										   { "name": "USA", "value": "US"},
										   { "name": "Canada", "value": "CA"}
										]}
									/>
								</div>
								<h4>Contact Information</h4>
								<div className="col-two">
									<InputRow required onChange={this.updateLocation} data={store.id} label="* Store Phone" type="text"  property="phone" value={store.phone} />
									<InputRow onChange={this.updateLocation} data={store.id} label="Store Fax" type="text"  property="fax" value={store.fax} />
								</div>
		            		</div>
	            		)
					)}
				</div>

	        </div>
		)
	}
}

export default StepTwo;