import { mon } from './uiHelpers';


// converts camelCase => Camel Case
export const camelToUpperCase = (val) => {
		val = val.replace(/([A-Z])/g, ' $1');
		val = val.replace(/^./, function(str){
			return str.toUpperCase();
		});
		return val;
}

// converts null values to empty string so input placeholders will work
export const valueEmpty = (value) => {
	if (value === null || value === "null") {
		value = "";
	}
	return value;
}
// is checkbox checked or not based on value
export const isChecked = (value) => {
	if (value && value !== 0 && value !== false && value !== "0" && value !== "false") {
		return "checked";
	}
}
// is a select option selected or not based on value
export const isSelected = (option, value) => {
	if (String(option) === String(value)) {
		return "selected";
	}
}

// converts 2019-10-22 to October 22, 2019
export const formatDateDisplay = (date) => {
	var year = date.split('-')[0];
	var month = date.split('-')[1];
	var day = date.split('-')[2];
	
	month = mon[month-1];

	return month+" "+day+", "+year;
}

// converts time stamp to date
export const formatDate = (timestamp) => {
	timestamp = new Date(timestamp).toLocaleString();
	var date = timestamp.split(',')[0];
	var time = timestamp.split(',')[1];
	return [date, time];
}

// adds leading 0 to dates below 10
export const formatMonth = (date) => {
    if (date < 10) { date = "0"+date }
    return date;
}

// converts seconds to timer format 1234 => hr:mn:sc
export const secondsToTime = (s) => {
	var time;
	s = Math.round(s)
	if (s) {
		var h = Math.floor(s/3600);
		if (h<10) {
			h = "0"+h;
		}

		s %= 3600;
		var m = Math.floor(s/60);
		if (m<10) {
			m = "0"+m;
		}

		s = s % 60;
		if (s<10) {
			s = "0"+s;
		}

		time = h+":"+m+":"+s;
		// 00:00:00
	} else {
		time = "00:00:00";
	}
	return time;
}

export const numberToLocal = (number) => {
	number = parseFloat(number);
	number = number.toLocaleString();
	return number;
}
// converts decimals to price: 5432.1 => $5,432.10
export const numberToPrice = (number) => {
	number = parseFloat(number);
	number = number.toLocaleString("en-US", {style:"currency", currency:"USD"});

	if (number === "$NaN" || number === "-$NaN") {
		number = "$0.00";
	}
    return number;
}
// converts decimals to percent: 54.3210987654321 => 54.32%
export const numberToPercent = (number) => {
	number = parseFloat(number);
	number = number.toFixed(2);
	number = number+"%";
	return number;
}

// returns the value of a given peram from the URL
export const getUrlParameter = (name) => {
	var urlParams = new URLSearchParams(window.location.search);
	return urlParams.get(name);
};

export const checkStringMatch = (idA, idB, idStatus) => {
	var A = document.querySelector('#'+idA).value;
	var B = document.querySelector('#'+idB).value;
	var status = false;
	if (A === B) {
		status = true;
	}
	return status;	
}
