import React from 'react';

import * as Api from './api/endpoints';
import * as Data from './components/data/data';
import { tokenParams, basicGET, basicJSONPOST } from './api/fetchHelpers';

import { LoginForm, ForgotPassForm, PassResetForm, ConfirmLoginForm } from './helpers/uiComponents';

import Header from './components/header/header';
import Navigation from './components/nav/nav';

import StepOne from './components/pages/stepOne';
import StepTwo from './components/pages/stepTwo';
import StepThree from './components/pages/stepThree';
import StepFour from './components/pages/stepFour';
import StepFive from './components/pages/stepFive';

import Footer from './components/footer/footer';

import velocityLogo from './images/velocity-logo-trans.png';
import { getUrlParameter, checkStringMatch } from './helpers/uiFunctions';

class App extends React.Component {
    constructor(props) {
        super(props);

        var step;
        if (localStorage.getItem('currentStep')) {
            step = localStorage.getItem('currentStep');
        } else {
            step = 1;
        }

        this.state = {
            accessToken: null,

            isLoggedIn: false,
            forgotPass: false,
            resetPass: false,
            confirmLogin: false,
            loadingClient: true,

            roles: [],
            endpoints: [],
            authCreds: [],
            currentClient: null,
            contacts: null,
            locations: null,
            credentials: null,
            clientCode: null,
            clientCodes: [],
            userEmail: null,

            transactionComplete: false,

            currentStep: step,
        };

        this.login = this.login.bind(this);
        this.confirmLogin = this.confirmLogin.bind(this);
        this.logout = this.logout.bind(this);
        this.showReset = this.showReset.bind(this);

        this.handleStep = this.handleStep.bind(this);
        this.save = this.save.bind(this);
        
        this.setClient = this.setClient.bind(this);
        this.updateClient = this.updateClient.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
        this.removeImage = this.removeImage.bind(this);
        
        this.addContact = this.addContact.bind(this);
        this.deleteContact = this.deleteContact.bind(this);
        this.updateContact = this.updateContact.bind(this);

        this.addLocation = this.addLocation.bind(this);        
        this.deleteLocation = this.deleteLocation.bind(this);
        this.updateLocation = this.updateLocation.bind(this);

        this.addCredential = this.addCredential.bind(this);
        this.deleteCredential = this.deleteCredential.bind(this);
        this.updateCredential = this.updateCredential.bind(this);
        this.addTransaction = this.addTransaction.bind(this);
    }

    componentDidMount() {
        //console.log("running componentDidMount()")
        let expires = localStorage.getItem('expires');
        let token = localStorage.getItem('accessToken');
        let now = Date.now();
        let page = getUrlParameter("page");

        if (page === "forgot-password") {
            this.setState({ accessToken: null, resetPass: true });
            localStorage.clear();
        } else if (expires < now) {
            // console.log("expired");
            this.setState({ accessToken: null, forgotPass: false });
            localStorage.clear();
        } else if (!token) {
            // console.log("no token");
            this.setState({ accessToken: null, forgotPass: false });
            localStorage.clear();
        } else {
            this.setState({ accessToken: token, forgotPass: false });
            this.getCredentials(token);
        }
    }
// ------------------------------------------------------------------- CREDENTIALS FUNCTIONS
    getCredentials(token) {
        console.log("running getCredentials()")
        fetch(Api.serviceSso+'decode', basicGET(token))
        .then(response => {
            if (response.ok) { return response.json(); } 
            else { throw new Error('Something went wrong getting the client role from sso ...'); }
        })
        .then(data => {
            console.log(data);
            if (data.success) {
                this.setState({
                    roles: data.result.roles,
                    endpoints: data.result.endpoints,
                });                
                if (data.result.clientCodes.length === 1) {
                    this.getClientData(data.result.clientCodes[0]);
                    this.setState({clientCode: data.result.clientCodes[0]});
                } 
                if (data.result.clientCodes.length > 1) {
                    //do nothing, need to choose a client first
                    this.loadClientNames(data.result.clientCodes);
                }
                if (data.result.clientCodes.length < 1) {
                    document.querySelector('.login-messages').innerHTML = "There is currently no active account for the Email you entered.";
                }
                this.getAuthNetCredentials(token)
                
            } else {
                //hasnt set up a client yet
                document.querySelector('.login-messages').innerHTML = "There is currently no active account for the Email you entered.";
                //
            }
        })
        .catch(error => console.log(error));
    }
    setClient() {
        let clientCode = document.getElementById("setClient").value;
        this.setState({clientCode: clientCode});
        this.getClientData(clientCode);
    }

// ------------------------------------------------------------------- LOGIN/LOGOUT FUNCTIONS
    login() {
        console.log("running login()")
        let user = document.getElementById("email").value;
        let pass = document.getElementById("password").value;
        var params = tokenParams(user, pass);

        fetch(Api.serviceToken, params)
        .then(response => {
            if (response.ok) { return response.json(); } 
            else {
                throw new Error('The Email and Password you entered did not work. Please check and try again.');
            }
        })
        .then(data => {
            var timestamp = Date.now();
            var exp = timestamp + (data.expires_in * 1000);
            
            localStorage.setItem('expires', exp);
            localStorage.setItem('accessToken', data.access_token);

            this.setState({accessToken: data.access_token});

            this.componentDidMount();
        })
        .catch(error => {
            console.log(error);
            document.querySelector('.login-messages').innerHTML = "The Email and Password you entered did not work.</br>Please check and try again.";
        });
    }
    confirmLogin() {
        console.log("do stuff");
        this.setState({
            isLoggedIn: true
        });
    }
    logout() {
        this.save(this.state.currentStep);
        this.setState({
            isLoggedIn: false,
            confirmLogin: false,
            accessToken: null,

            roles: [],
            endpoints: [],
            currentClient: null,
            contacts: null,
            locations: null,
            credentials: null,
            clientCode: null,
            clientCodes: [],
            userEmail: null,

            currentStep: 1,
        });
        localStorage.clear();
    }
    showReset(form) {
        // console.log(form);
        if (form === "login") {
            this.setState({
                isLoggedIn: false,
                forgotPass: false,
                resetPass: false,
            });
        }
        if (form === "forgot") {
            this.setState({
                isLoggedIn: false,
                forgotPass: true,
                resetPass: false,
            });
        }
    }
    sendResetEmail() {
        var email = document.getElementById("forgot-email").value;
        //console.log(email);

        if (email) {
            const Http = new XMLHttpRequest();
            const url= Api.serviceSso+"forgot/"+email;
            Http.open("POST", url);
            Http.send();

            Http.onreadystatechange = (e) => {
                // console.log(Http.responseText);

                if (Http.responseText === "ok") {
                    this.showReset();
                    document.querySelector('.login-messages').innerHTML = "An email with a password reset link has been sent.";
                    setTimeout(function() {
                        document.querySelector('.login-messages').innerHTML = "";
                    }, 7000);
                } else {
                    document.querySelector('.forgot-messages').innerHTML = "There was a problem sending the password reset email, please check your email and try again.";
                }
            }
        } else {
            document.querySelector('.forgot-messages').innerHTML = "Please provide a valid email address and try again.";
        }

    }
    validatePassword(a, b, idStatus) {
        var status = checkStringMatch(a, b);
        var button = document.getElementById("reset-submit");

        if (status) {
            document.querySelector('#'+idStatus).classList.remove("fail");
            document.querySelector('#'+idStatus).classList.add("success");
            button.removeAttribute("disabled");

        } else {
            document.querySelector('#'+idStatus).classList.remove("success");
            document.querySelector('#'+idStatus).classList.add("fail");
            button.setAttribute("disabled", true);
        }
    }
    submitReset() {
        //console.log("reset");
        var username = document.querySelector('#reset-email').value;
        var password = document.querySelector('#reset-password').value;
        var noonce = document.querySelector('#reset-noonce').value;
        var body = {
            "pw": password,
            "n": noonce,
        }
        if ( password.length > 9 ) {
            fetch(Api.serviceSso+"password/"+username, basicJSONPOST(this.state.accessToken, body))
            .then(response => {
                if (response.ok) { return response.json(); } 
                else { throw new Error('Something went wrong submitting the new password...'); }
            })
            .then(data => {
                if (data.success) {
                    this.setState({ isLoggedIn: false, forgotPass: false });
                    window.location = window.location.href.split("?")[0];
                } else {
                    document.querySelector('.reset-messages').innerHTML = "There was a problem resetting your password, please try again.";
                    setTimeout(function() {
                        window.location = window.location.href.split("?")[0];
                    }, 3000);
                }
            })
            .catch(error => console.log(error));
        } else {
            document.querySelector('.reset-messages').innerHTML = "Your password must be at least 10 characters long.";
        }
    }


// ------------------------------------------------------------------- DATA FUNCTIONS
    loadClientNames(clientCodes) {
        var arr = [];
        for (let i = 0; i < clientCodes.length; i++) {
            Data.getClientData(
                this.state.endpoints.serviceClients, clientCodes[i], this.state.accessToken
            ).then(data => {
                if (data.success) {
                    if (data.result.status === "onboarding") {
                        arr.push({"name": data.result.name, "value":data.result.clientCode})
                    }
                }
            });
            
        }
        setTimeout(() => {
            //console.log(arr, arr.length);
            this.setState({clientCodes: arr});
        }, 500);
    }
    getClientData(clientCode) {
        //console.log("running getClientData() for " + clientCode)
        Data.getClientData(
            this.state.endpoints.serviceClients, clientCode, this.state.accessToken
        ).then(data => {
            let confirm = false;
            let login = false;
            if (data.success) {
                if (data.result.status === "onboarding") {
                    // trigger a fetch of the client data from clients service
                    // console.log("has clientCode: "+data.result.clientCode);
                    login = true;
                } else if (data.result.status === "active") {
                    confirm = true;
                }
                // else if status is archived, dont allow login to happen
                this.setState({
                    currentClient: data.result,
                    contacts: data.result.contacts,
                    locations: data.result.locations,
                    isLoggedIn: login,
                    confirmLogin: confirm,
                });
            } else {
                //hasnt set up a client yet
                document.querySelector('.login-messages').innerHTML = "There is currently no active account for the Email you entered.";
                //
                this.setState({
                    isLoggedIn: login,
                    confirmLogin: confirm,
                });
            }
        });

        Data.getClientCredentials(
            this.state.endpoints.serviceClients, clientCode, this.state.accessToken
        ).then(data => {
            var newState = data.result;
            
            newState = newState.filter(function( obj ) {
                return obj.group === "social" || obj.group === "shaw" || obj.group === "crm";
            });

            this.setState({
                credentials: newState,
                loadingClient: false,
            });
        })

        Data.getTransactions(
            this.state.endpoints.serviceClients, clientCode, this.state.accessToken
        ).then(data => {
            // console.log(data);
            if (data.success && data.result.length > 0) {
                this.setState({transactionComplete: true});
            }
        })
    }
    getAuthNetCredentials(token) {
        fetch(Api.serviceSso+'credential/prod/authnet', basicGET(token))
        .then(response => {
            if (response.ok) { return response.json(); } 
            else { throw new Error('Something went wrong getting the auth.net credentials...'); }
        })
        .then(data => {
            this.setState({
                authCreds: data.result,
            });
        })
        .catch(error => console.log(error));
    }
    


// ------------------------------------------------------------------- UI FUNCTIONS
    handleStep(newStepId) {
        this.save(this.state.currentStep);

        this.setState({ currentStep: newStepId });
        localStorage.setItem('currentStep', newStepId);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    save(currentStep) {
        // console.log("save step "+currentStep);
        var i;
        var currentClient = {};

        if( currentStep === 1 || currentStep === "1" ) {            
            currentClient = this.state.currentClient;
            var contacts = this.state.contacts;
            // console.log(currentClient);

            Data.saveClientData(
                this.state.endpoints.serviceClients+"client", currentClient, this.state.accessToken
            ).then(data => {
                // console.log(data);
            });
            for (i = contacts.length - 1; i >= 0; i--) {
                delete contacts[i].name;
                Data.save(
                    this.state.endpoints.serviceClients+"contact", this.state.accessToken, contacts[i]
                ).then(data => {
                    // console.log(data);
                });
            }
        }
        if( currentStep === 2 || currentStep === "2" ) {            
            var locations = this.state.locations;
            var credentials = this.state.credentials;
            for (i = locations.length - 1; i >= 0; i--) {
                Data.save(
                    this.state.endpoints.serviceClients+"location", this.state.accessToken, locations[i]
                ).then(data => {
                    // console.log(data);
                });
            }
            for (i = credentials.length - 1; i >= 0; i--) {
                Data.save(
                    this.state.endpoints.serviceClients+"credential", this.state.accessToken, credentials[i]
                ).then(data => {
                    // console.log(data);
                });
            }
            
        }
        if( currentStep === 3 || currentStep === "3" ) {            
            currentClient = this.state.currentClient;
            
            // console.log(currentClient);

            Data.saveClientData(
                this.state.endpoints.serviceClients+"client", currentClient, this.state.accessToken
            ).then(data => {
                // console.log("contract signed");
            });
        }
    }

    updateClient(key, value) {
        // console.log(key, value);
        var newState = this.state.currentClient;
        newState[key] = value;
        this.setState({currentClient: newState});
    }
    uploadImage(logo) {
        Data.addImage(
            this.state.endpoints.serviceClients+this.state.clientCode+"/logo",
            logo,
            this.state.accessToken
        ).then(data => {
            this.updateClient("logo", data.result.logo);
        });
    }
    removeImage() {
        Data.deleteImage(
            this.state.endpoints.serviceClients+this.state.clientCode+"/logo",
            this.state.accessToken
        ).then(data => {
            if (data.success) {
                console.log("deleted logo");
            }
            this.updateClient("logo", null);
        });
    }

    addContact(type) {
        var postBody = {
            "clientCode": this.state.clientCode,
            "type": type,
            "name": "New Contact"
        }
        Data.save(
            this.state.endpoints.serviceClients+"contact", this.state.accessToken, postBody
        ).then(data => {
            var newState = this.state.contacts;
            newState.push(data.result);
            this.setState({ contacts: newState });
        });
    }
    deleteContact(id) {
        Data.deleteRecord(
            this.state.endpoints.serviceClients+"contact/"+id, this.state.accessToken
        ).then(data => {
            if (data.success === true) {
                var newState = this.state.contacts;
                newState = newState.filter(function( obj ) {
                    return obj.id !== id;
                });
            }
            this.setState({ contacts: newState });
        });
    }
    updateContact(id, key, value) {
        const keyIndex = this.state.contacts.findIndex(contact => contact.id === id )
        let newState = this.state.contacts;
        newState[keyIndex] = {...newState[keyIndex], [key]: newState[keyIndex][key] = value}

        this.setState({contacts: newState});
    }

    addLocation() {
        var postBody = {
            "clientCode": this.state.clientCode,
            "type": "store",
            "name": "New Store"
        }
        Data.save(
            this.state.endpoints.serviceClients+"location", this.state.accessToken, postBody
        ).then(data => {
            var newState;
            if (this.state.locations === null) {
                newState = []
            } else {
                newState = this.state.locations;
            }
            newState.push(data.result);
            this.setState({ locations: newState });
        });
    }
    deleteLocation(id) {
        Data.deleteRecord(
            this.state.endpoints.serviceClients+"location/"+id, this.state.accessToken
        ).then(data => {
            if (data.success === true) {
                var newState = this.state.locations;
                newState = newState.filter(function( obj ) {
                    return obj.id !== id;
                });
            }
            this.setState({ locations: newState });
        });
    }
    updateLocation(id, key, value) {
        const keyIndex = this.state.locations.findIndex(store => store.id === id )
        let newState = this.state.locations;
        newState[keyIndex] = {...newState[keyIndex], [key]: newState[keyIndex][key] = value}

        // console.log(newState);

        this.setState({locations: newState});
    }

    addCredential(group, type, platform) {
        // console.log(group, type, platform);

        var postBody = {
            "clientCode": this.state.clientCode,
            "siteCode": "all",
            "instance": "prod",
            "type": type,
            "platform": platform,
            "group": group,
            "active": false,
        }
        Data.save(
            this.state.endpoints.serviceClients+"credential", this.state.accessToken, postBody
        ).then(data => {
            var newState;
            if (this.state.credentials === null) {
                newState = []
            } else {
                newState = this.state.credentials;
            }
            newState.push(data.result);
            this.setState({ credentials: newState });
        });
    }
    deleteCredential(id) {
        Data.deleteRecord(
            this.state.endpoints.serviceClients+"credential/"+id, this.state.accessToken
        ).then(data => {
            if (data.success === true) {
                var newState = this.state.credentials;
                newState = newState.filter(function( obj ) {
                    return obj.id !== id;
                });
            }
            this.setState({ credentials: newState });
        });
    }
    updateCredential(id, key, value) {
        const keyIndex = this.state.credentials.findIndex(cred => cred.id === id )
        let newState = this.state.credentials;
        newState[keyIndex] = {...newState[keyIndex], [key]: newState[keyIndex][key] = value}

        // console.log(newState);

        this.setState({credentials: newState});
    }
    addTransaction(transactionId, name, amount) {
        var postBody = {
            "clientCode": this.state.clientCode,
            "transactionId": transactionId,
            "name": name,
            "amount": amount,
        }
        Data.save(
            this.state.endpoints.serviceClients+"transaction", this.state.accessToken, postBody
        ).then(data => {
            if (data.success) {
                this.setState({transactionComplete: true});
            }
        });
    }
    sendAlert() {
        const client = this.state.currentClient;
        const location = this.state.locations.filter(function( obj ) {
            return obj.type === "billing";
        });
        const owner = this.state.contacts.filter(function( obj ) {
            return obj.type === "Owner";
        });
        const acct = this.state.contacts.filter(function( obj ) {
            return obj.type === "billing";
        });
        var body = {
            "clientCode": client.clientCode,
            "toEmail": "joni@mobile-marketing.agency",
            "toName": "Joni Dreibelbis",
            "fromEmail": "support@mobile-marketing.agency",
            "fromName": "Onboarding Portal",
            "optedIn": true,
            "templateId": "d-1edd383ec9bb468bbf04a1864f802a6c",
            "templateData": {
                "dbaName": client.name,
                "w9Name": client.w9Name,
                "billingAddress":location.address+" "+location.city+", "+location.state+" "+location.postalCode,
                "billingPhone": location.phone,
                "ownerName": owner.name,
                "ownerEmail": owner.email,
                "ownerPhone": owner.phone,
                "acctsName": acct.name,
                "acctsEmail": acct.email,
                "acctsPhone": acct.phone
            }
        }
        Data.sendAlert(
            this.state.endpoints.serviceCrm+"email/send", body, this.state.accessToken
        ).then(data => {
            console.log(data);
        });
    }
    



// ------------------------------------------------------------------- RENDER
    render() {
        const { loadingClient, isLoggedIn, clientCodes, forgotPass, resetPass, confirmLogin, userEmail, currentClient, contacts, locations, credentials, currentStep, authCreds, transactionComplete } = this.state;
        return (
            <div className="velocity-onboarding">
                { isLoggedIn ? (
                    <React.Fragment>
                        <Header
                            isLoggedIn={isLoggedIn}
                            logout={this.logout}
                        />
                        <div className="body-container">
                            <nav>
                                <Navigation
                                    currentStep={currentStep}
                                />
                            </nav>
                            {(loadingClient) ? (
                                <div className="loading">
                                    <i className="fas fa-spinner"></i>
                                </div>
                            ) : (
                                <section id="form-wrapper">
                                    {(currentStep === "1" || currentStep === 1) && (
                                        <StepOne
                                            email={userEmail}
                                            currentClient={currentClient}
                                            contacts={contacts}
                                            addContact={this.addContact}
                                            delContact={this.deleteContact}
                                            updateClient={this.updateClient}
                                            updateContact={this.updateContact}
                                        />
                                    )}
                                    {(currentStep === "2" || currentStep === 2) && (
                                        <StepTwo
                                            currentClient={currentClient}    
                                            updateClient={this.updateClient}
                                            removeImage={this.removeImage}
                                            addImage={this.uploadImage}
                                            locations={locations}
                                            addLocation={this.addLocation}
                                            delLocation={this.deleteLocation}
                                            updateLocation={this.updateLocation}
                                            credentials={credentials}
                                            addCredential={this.addCredential}
                                            delCredential={this.deleteCredential}
                                            updateCredential={this.updateCredential}
                                        />
                                    )}
                                    {(currentStep === "3" || currentStep === 3) && (
                                        <StepThree
                                            currentClient={currentClient}
                                            updateClient={this.updateClient}
                                        />
                                    )}
                                    {(currentStep === "4" || currentStep === 4) && (
                                        <StepFour
                                            currentClient={currentClient}
                                            addTransaction={this.addTransaction}
                                            authCreds={authCreds}
                                            transactionComplete={transactionComplete}
                                            updateClient={this.updateClient}
                                            sendAlert={this.sendAlert}
                                        />
                                    )}
                                    {(currentStep === "5" || currentStep === 5) && (
                                        <StepFive
                                            
                                        />
                                    )}
                                </section>
                            )}
                        </div>
                        
                        <Footer
                            next={this.handleStep}
                            back={this.handleStep}
                            currentStep={currentStep}
                            currentClient={currentClient}
                            contacts={contacts}
                            locations={locations}
                            transactionComplete={transactionComplete}
                        />
                    </React.Fragment>
                
                ) : (
                    <div className="login-page-wrapper">
                        <div className="login-wrapper">
                            <img src={velocityLogo} alt="logo" className="header-logo" />
                            <h1>Power Your Future with Velocity</h1>
                            <h5>An Exclusive Offering of the Shaw Flooring Network</h5>
                            <p>Sign up for Velocity today and plug into a powerhouse of digital advantages that strategically work together to</p>
                            <ul>
                                <li>Differentiate your business</li>
                                <li>Expand your online presence</li>
                                <li>Optimize consumer engagement</li>
                                <li>Drive in-market leads</li>
                                <li>Increase conversions</li>
                            </ul>
                            
                            {(!isLoggedIn && !forgotPass && !resetPass && !confirmLogin) && (
                                <LoginForm
                                    submit={this.login}
                                    showReset={this.showReset}
                                    clientCodes={clientCodes}
                                    setClient={this.setClient}
                                />
                            )}
                            {(!isLoggedIn && forgotPass && !resetPass && !confirmLogin) && (
                                <ForgotPassForm
                                    submit={this.sendResetEmail}
                                    showReset={this.showReset}
                                />
                            )}
                            {(!isLoggedIn && !forgotPass && resetPass && !confirmLogin) && (
                                <PassResetForm
                                    submit={this.submitReset}
                                    validate={this.validatePassword}
                                    getUrlParameter={getUrlParameter}
                                />
                            )}
                            {(confirmLogin === true) && (
                                <ConfirmLoginForm
                                    confirm={this.confirmLogin}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default App;